import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../services';

@Component({
  selector: 'app-index-navbar',
  templateUrl: './index-navbar.component.html',
})
export class IndexNavbarComponent implements OnInit {
  navbarOpen = false;

  constructor(private analyticsServices: AnalyticsService) {}

  ngOnInit(): void {}

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }
}

<app-index-navbar></app-index-navbar>
<main>
  <div
    class="pt-16 flex items-center overflow-hidden sticky bg-black home-slider"
  >
    <owl-carousel-o [options]="customOptions" #owlCar>
      <!-- <ng-template carouselSlide>
          <img src="../../../assets/img/slider-1.jpg">
        </ng-template>

        <ng-template carouselSlide>
          <img src="../../../assets/img/slider-2.jpg">
        </ng-template>

        <ng-template carouselSlide>
          <img src="../../../assets/img/slider-3.jpg">
        </ng-template> -->

      <ng-template carouselSlide>
        <div
          class="slider-item js-fullheight"
          style="background-image: url(../../../assets/img/slide-img1.jpg)"
        >
          <div class="overlay"></div>
          <div class="container">
            <div
              class="row no-gutters slider-text js-fullheight align-items-center justify-content-around"
            >
              <div class="mr-16 pr-16 col-md-12 ftco-animate">
                <div class="text w-100 text-left">
                  <h1>
                    <span class="hero-heading">
                      Over the Stack Solutions <br
                    /></span>
                    A dynamic software company committed to unleashing
                    tomorrow's solutions today.
                  </h1>
                  <h2 class="mb-3">
                    providing industry-leading digital solutions that drive
                    measurable growth for your business. We are your growth
                    partner.
                  </h2>
                </div>
              </div>
              <div class="col-md-12 hero-image pt-16">
                <img
                  src="../../../assets/img/OTSS_ Final 1_with wording.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div
          class="slider-item js-fullheight"
          style="background-image: url(../../../assets/img/slide-img1.jpg)"
        >
          <div class="overlay"></div>
          <div class="container">
            <div
              class="row no-gutters slider-text js-fullheight align-items-center justify-content-center"
            >
              <div class="mr-16 pr-16 col-md-12 ftco-animate">
                <div class="text w-100 text-left">
                  <h1>
                    Solving your Business Needs,
                    <span class="hero-heading">
                      <br />
                      Creating Customized Software <br
                    /></span>
                    We work together with you to build custom software that
                    transforms your ideas into reality and propels your business
                    forward.
                  </h1>
                  <h2 class="mb-3">
                    We specialize in creating custom software solutions tailored
                    to your unique business needs.
                  </h2>
                </div>
              </div>
              <div class="col-md-12 hero-image pt-16">
                <img
                  src="../../../assets/img/Slider-image-2.png"
                  alt="Software Development"
                />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div
          class="slider-item js-fullheight"
          style="background-image: url(../../../assets/img/slide-img1.jpg)"
        >
          <div class="overlay"></div>
          <div class="container">
            <div
              class="row no-gutters slider-text js-fullheight align-items-center justify-content-around"
            >
              <div class="mr-16 pr-16 col-md-12 ftco-animate">
                <div class="text w-100 text-left">
                  <h1>
                    <span class="hero-heading">
                      Mobile App Development <br
                    /></span>
                    Bringing your business to fingertips, your business is not
                    just accessible; we can make it an experience in the palm of
                    their hands.
                  </h1>
                  <h2 class="mb-3">
                    We create exceptional mobile apps that engage users and
                    drive business growth.
                  </h2>
                </div>
              </div>
              <div class="col-md-12 hero-image pt-16">
                <img
                  src="../../../assets/img/Slider-image-3b.png"
                  alt="App Development"
                />
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template carouselSlide>
        <div
          class="slider-item js-fullheight"
          style="background-image: url(../../../assets/img/slide-img1.jpg)"
        >
          <div class="overlay"></div>
          <div class="container">
            <div
              class="row no-gutters slider-text js-fullheight align-items-center justify-content-center"
            >
              <div class="mr-16 pr-16 col-md-12 ftco-animate">
                <div class="text w-100 text-left">
                  <h1>
                    Elevate your Business Online through
                    <span class="hero-heading">
                      <br />
                      Web Development <br
                    /></span>
                    Unlock your business's full potential in the digital age
                    with our expert-driven solutions.
                  </h1>
                  <h2 class="mb-3">
                    In the vast landscape of the internet, we don't just build
                    websites; we architect digital experience
                  </h2>
                </div>
              </div>
              <div class="col-md-12 hero-image pt-16">
                <img
                  src="../../../assets/img/Slider-image-4.png"
                  alt="Web Development"
                />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
  <div class="container mx-auto px-4">
    <div class="py-12">
      <div class="flex flex-wrap -mx-4 justify-center">
        <div class="px-4 relative w-full text-center">
          <h2 class="text-4xl font-bold mt-3 mb-1 text-blueGray-700">
            Our Services
          </h2>
          <p class="mt-2 mb-4 text-xl leading-relaxed text-blueGray-400">
            Transform Your Business with Expert Solutions: From Software
            Development to AI Integration and Digital Marketing.
          </p>
        </div>
      </div>
      <div class="items-center flex flex-wrap -mx-4">
        <div class="ml-auto text-left mt-8 px-4 relative w-full lg:w-4/12">
          <div
            class="shadow-lg rounded border-0 relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg text-white bg-blueGray-500"
          >
            <img
              class="w-full flex-shrink-0 rounded-t-lg"
              src="assets/img/api-800x600.jpg"
              alt="..."
            />
            <blockquote class="p-6 pb-8 relative mb-4">
              <svg
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 583 95"
                class="block w-full absolute text-blueGray-700 h-95-px -top-94-px left-0"
              >
                <polygon
                  points="0,52 583,95 0,95"
                  class="text-blueGray-500 fill-current"
                ></polygon>
                <polygon
                  points="0,42 583,95 683,0 0,95"
                  opacity=".2"
                  class="text-blueGray-500 fill-current"
                ></polygon>
              </svg>
              <h4 class="text-2xl font-semibold mt-0 text-white">
                Software Development
              </h4>
              <p class="text-white mt-2">
                Our Software Development services provide custom, scalable, and
                innovative solutions tailored to meet your unique business
                needs. From concept to deployment, we deliver high-quality,
                reliable software that drives growth and streamlines operations,
                ensuring your business stays ahead in a competitive digital
                landscape.
              </p>
            </blockquote>
          </div>
        </div>
        <div class="mr-auto px-4 relative w-full lg:w-7/12">
          <div class="flex flex-wrap -mx-4">
            <div class="px-4 relative w-full md:w-6/12">
              <div
                class="bg-orange-500 mt-8 w-full rounded-lg text-left p-6 flex items-stretch"
              >
                <div class="flex-1 card">
                  <div
                    class="text-orange-500 bg-white shadow-lg rounded rounded-full justify-center items-center text-center p-2 mx-auto mb-5 inline-flex w-12 h-12"
                  >
                    <i class="fa-duotone fa-solid fa-brain text-xl"></i>
                  </div>
                  <h4 class="text-2xl font-semibold leading-normal text-white">
                    Artificial Intelligence
                  </h4>
                  <p class="mt-2 text-white">
                    Our Artificial Intelligence services harness the power of
                    cutting-edge AI technologies to transform your business. We
                    deliver tailored AI solutions that enhance automation,
                    improve decision-making, and drive innovation, helping you
                    stay ahead in the fast-evolving digital landscape.
                  </p>
                </div>
              </div>
            </div>
            <div class="px-4 relative w-full md:w-6/12">
              <div
                class="bg-lightBlue-500 mt-8 rounded-lg w-full text-left p-6 flex items-start"
              >
                <div class="flex-1">
                  <div
                    class="text-lightBlue-500 bg-white shadow-lg rounded rounded-full justify-center items-center text-center p-2 mx-auto mb-5 inline-flex w-12 h-12"
                  >
                    <i class="fa-solid fa-magnifying-glass-chart text-xl"></i>
                  </div>
                  <h4 class="text-2xl font-semibold leading-normal text-white">
                    Digital Marketing
                  </h4>
                  <p class="mt-2 text-white">
                    Our Digital Marketing services enhance your brand’s online
                    presence through SEO, content marketing, and social media
                    strategies. We deliver data-driven solutions that boost
                    visibility and increase conversions. Our mission is to help
                    your business thrive in a competitive digital landscape.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -mx-4">
            <div class="px-4 relative w-full md:w-6/12">
              <div
                class="bg-red-500 mt-8 rounded-lg text-left p-6 flex items-start"
              >
                <div class="flex-1">
                  <div
                    class="text-red-500 bg-white shadow-lg rounded rounded-full justify-center items-center text-center p-2 mx-auto mb-5 inline-flex w-12 h-12"
                  >
                    <i class="fa-solid fa-mobile-screen-button text-xl"></i>
                  </div>
                  <h4 class="text-2xl font-semibold leading-normal text-white">
                    Mobile Apps
                  </h4>
                  <p class="mt-2 text-white">
                    In the era of mobility, we specialize in mobile app
                    development that goes beyond functionality. Your business is
                    not just accessible; it's an experience in the palm of their
                    hands. We create mobile solutions that captivate and engage
                    your audience on the go.
                  </p>
                </div>
              </div>
            </div>
            <div class="px-4 relative w-full md:w-6/12">
              <div
                class="bg-emerald-500 mt-8 rounded-lg text-left p-6 flex items-start"
              >
                <div class="flex-1">
                  <div
                    class="text-emerald-500 bg-white shadow-lg rounded rounded-full justify-center items-center text-center p-2 mx-auto mb-5 inline-flex w-12 h-12"
                  >
                    <i class="fa-solid fa-globe text-xl"></i>
                  </div>
                  <h4 class="text-2xl font-semibold leading-normal text-white">
                    Web Development
                  </h4>
                  <p class="mt-2 text-white">
                    In the vast landscape of the internet, we don't just build
                    websites; we architect digital experiences. Our web
                    development services go beyond mere coding – we breathe life
                    into your brand online, ensuring every click is an
                    exploration.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-12">
      <div class="flex flex-wrap -mx-4 justify-center">
        <div class="px-4 relative w-full text-center">
          <span
            class="text-pink-500 bg-pink-200 text-xs font-bold inline-block py-1 uppercase uppercase last:mr-0 mr-1 leading-tight rounded-full px-3"
          >
            Choose Us
          </span>
          <h2 class="text-4xl font-bold mt-3 mb-1 text-blueGray-700">
            Qualities That Set Us Apart
          </h2>
        </div>
      </div>
      <div class="items-center flex flex-wrap -mx-4">
        <div class="text-left px-4 relative w-full lg:w-4/12">
          <div
            class="text-lightBlue-otss border-lightBlue-500 shadow-lg rounded-full justify-center items-center inline-flex text-center mb-2 bg-white w-16 h-16"
          >
            <i class="fa-solid fa-microchip text-1625"></i>
          </div>
          <h6 class="text-xl font-semibold leading-tight mb-2 mt-6">
            Innovative Solutions
          </h6>
          <p class="mb-2 text-blueGray-400">
            We deliver cutting-edge software solutions tailored to your unique
            business needs. Our team stays ahead of industry trends, ensuring
            your business is equipped with the latest technology to stay
            competitive.
          </p>
        </div>
        <div class="text-left px-4 relative w-full lg:w-4/12">
          <div
            class="text-blue-otss border-lightBlue-500 shadow-lg rounded-full justify-center items-center inline-flex text-center mb-2 bg-white w-16 h-16"
          >
            <i class="fas fa-cog text-1625"></i>
          </div>
          <h6 class="text-xl font-semibold leading-tight mb-2 mt-6">
            Expertise and Experience
          </h6>
          <p class="mb-2 text-blueGray-400">
            With years of experience across diverse industries, our experts
            bring deep technical knowledge and strategic insight to every
            project. We turn complex challenges into streamlined, effective
            solutions.
          </p>
        </div>
        <div class="text-left px-4 relative w-full lg:w-4/12">
          <div
            class="text-emerald-500 border-emerald-500 shadow-lg rounded-full justify-center items-center inline-flex text-center mb-2 bg-white w-16 h-16"
          >
            <i class="fas fa-atom text-1625"></i>
          </div>
          <h6 class="text-xl font-semibold leading-tight mb-2 mt-6">
            Customization at Its Best
          </h6>
          <p class="mb-2 text-blueGray-400">
            Every business is different, and so are our solutions. We take the
            time to understand your goals, delivering software that’s fully
            customized to meet your specific requirements and objectives.
          </p>
        </div>
        <div class="text-left px-4 relative w-full lg:w-4/12">
          <div
            class="text-orange-otss border-orange-500 shadow-lg rounded-full justify-center items-center inline-flex text-center mb-2 bg-white w-16 h-16"
          >
            <i class="fa-solid fa-wand-magic-sparkles text-1625"></i>
          </div>
          <h6 class="text-xl font-semibold leading-tight mb-2 mt-6">
            Commitment to Quality
          </h6>
          <p class="mb-2 text-blueGray-400">
            We prioritize quality at every stage of development, ensuring your
            software is reliable, scalable, and built to last. Our rigorous
            testing process guarantees a smooth, flawless user experience.
          </p>
        </div>
        <div class="text-left px-4 relative w-full lg:w-4/12">
          <div
            class="text-red-otss border-red-500 shadow-lg rounded-full justify-center items-center inline-flex text-center mb-2 bg-white w-16 h-16"
          >
            <i class="fa-solid fa-hands-asl-interpreting text-1625"></i>
          </div>
          <h6 class="text-xl font-semibold leading-tight mb-2 mt-6">
            End-to-End Support
          </h6>
          <p class="mb-2 text-blueGray-400">
            From concept and design to development and deployment, we offer
            full-cycle software development services. Our team provides ongoing
            support and maintenance, ensuring your system stays optimized and up
            to date.
          </p>
        </div>
        <div class="text-left px-4 relative w-full lg:w-4/12">
          <div
            class="text-red-500 border-indigo-500 shadow-lg rounded-full justify-center items-center inline-flex text-center mb-2 bg-white w-16 h-16"
          >
            <i class="fa-solid fa-meteor text-1625"></i>
          </div>
          <h6 class="text-xl font-semibold leading-tight mb-2 mt-6">
            Timely Delivery
          </h6>
          <p class="mb-2 text-blueGray-400">
            We understand the importance of timelines. With a structured
            workflow and clear communication, we deliver your projects on time,
            without compromising on quality.
          </p>
        </div>
      </div>
      <div class="flex flex-wrap -mx-4 justify-center">
        <div class="px-4 relative w-full text-center">
          <h2 class="text-4xl font-bold mt-3 mb-1 text-blueGray-700">
            Let’s Build the Future Together
          </h2>
          <p class="mt-2 mb-4 text-xl leading-relaxed text-blueGray-400">
            Partner with us for innovative, reliable, and customized software
            solutions that elevate your business. Our expertise, dedication to
            quality, and passion for technology will help you achieve your
            digital transformation goals. Let’s create success together!
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="py-12">
      <div class="mb-12 flex flex-wrap -mx-4 justify-center">
        <div class="px-4 relative w-full lg:w-8/12 text-center">
          <h6 class="mb-2 font-bold uppercase text-emerald-500">
            Our latest blogposts
          </h6>
          <h3 class="text-3xl font-bold mt-3 mb-1 text-blueGray-700">
            Check the news for this month
          </h3>
          <p class="mt-2 mb-4 text-xl leading-relaxed text-blueGray-400">
            I always felt like I could do anything. That’s the main thing people
            are controlled by! Thoughts- their perception of themselves!
          </p>
        </div>
      </div>
      <div class="items-center flex flex-wrap -mx-4">
        <div class="px-4 relative w-full lg:w-3/12">
          <div
            class="h-430-px overflow-hidden relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg transition-all duration-150 ease-in-out hover:z-1 hover:transform hover:scale-110 group rounded-lg mb-4"
          >
            <div
              class="absolute w-full h-full bg-50-center bg-cover transition-all duration-1000 ease-in-out group-hover:transform group-hover:scale-110 rounded-lg"
              style="
                background-image: url('assets/img/guitar-play.jpg');
                backface-visibility: hidden;
              "
            ></div>
            <div
              class="absolute w-full h-full bg-black opacity-50 rounded-lg"
            ></div>
            <a href="#pablo">
              <div class="absolute text-left p-6 bottom-0">
                <h6 class="text-xl leading-normal mb-0 text-white opacity-75">
                  All the beautiful places
                </h6>
                <h5
                  class="text-2xl font-bold leading-tight mt-0 mb-2 text-white"
                >
                  Research by Bang &amp; Olufsen on city sounds and music
                </h5>
              </div>
            </a>
          </div>
        </div>
        <div class="px-4 relative w-full lg:w-3/12">
          <div
            class="h-430-px overflow-hidden relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg transition-all duration-150 ease-in-out hover:z-1 hover:transform hover:scale-110 group rounded-lg mb-4"
          >
            <div
              class="absolute w-full h-full bg-50-center bg-cover transition-all duration-1000 ease-in-out group-hover:transform group-hover:scale-110 rounded-lg"
              style="
                background-image: url('assets/img/thomas.jpg');
                backface-visibility: hidden;
              "
            ></div>
            <div
              class="absolute w-full h-full bg-black opacity-50 rounded-lg"
            ></div>
            <a href="#pablo">
              <div class="absolute text-left p-6 bottom-0">
                <h6 class="text-xl leading-normal mb-0 text-white opacity-75">
                  Spectrum Boats
                </h6>
                <h5
                  class="text-2xl font-bold leading-tight mt-0 mb-2 text-white"
                >
                  Data Virtualization and Boats Startups
                </h5>
              </div>
            </a>
          </div>
        </div>
        <div class="px-4 relative w-full lg:w-3/12">
          <div
            class="h-430-px overflow-hidden relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg transition-all duration-150 ease-in-out hover:z-1 hover:transform hover:scale-110 group rounded-lg mb-4"
          >
            <div
              class="absolute w-full h-full bg-50-center bg-cover transition-all duration-1000 ease-in-out group-hover:transform group-hover:scale-110 rounded-lg"
              style="
                background-image: url('assets/img/ashim.jpg');
                backface-visibility: hidden;
              "
            ></div>
            <div
              class="absolute w-full h-full bg-black opacity-50 rounded-lg"
            ></div>
            <a href="#pablo">
              <div class="absolute text-left p-6 bottom-0">
                <h6 class="text-xl leading-normal mb-0 text-white opacity-75">
                  Flying on pandemic
                </h6>
                <h5
                  class="text-2xl font-bold leading-tight mt-0 mb-2 text-white"
                >
                  New Challenges when you want to fly to new areas
                </h5>
              </div>
            </a>
          </div>
        </div>
        <div class="px-4 relative w-full lg:w-3/12">
          <div
            class="h-430-px overflow-hidden relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg transition-all duration-150 ease-in-out hover:z-1 hover:transform hover:scale-110 group rounded-lg mb-4"
          >
            <div
              class="absolute w-full h-full bg-50-center bg-cover transition-all duration-1000 ease-in-out group-hover:transform group-hover:scale-110 rounded-lg"
              style="
                background-image: url('assets/img/dane.jpg');
                backface-visibility: hidden;
              "
            ></div>
            <div
              class="absolute w-full h-full bg-black opacity-50 rounded-lg"
            ></div>
            <a href="#pablo">
              <div class="absolute text-left p-6 bottom-0">
                <h6 class="text-xl leading-normal mb-0 text-white opacity-75">
                  Work from Home
                </h6>
                <h5
                  class="text-2xl font-bold leading-tight mt-0 mb-2 text-white"
                >
                  How meetings and behaviour are changing
                </h5>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="py-12 bg-blueGray-100">
      <div class="flex flex-wrap -mx-4">
        <div
          class="flex justify-center flex-col px-4 relative w-full md:w-4/12 ml-auto"
        >
          <h2 class="text-3xl font-bold mt-0">
            Choose a plan for your next project
          </h2>
          <p class="mt-2 mb-10 text-lg leading-relaxed text-blueGray-400">
            You have Free Unlimited Updates and Premium Support on each package.
            You also have 20 days to request a refund.
          </p>
          <div>
            <button
              class="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2 text-white bg-pink-500 border-pink-500 active:bg-pink-600 active:border-pink-600 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md"
            >
              Freelancer</button
            ><button
              class="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2 text-blueGray-800 bg-white border-white active:bg-blueGray-100 active:border-blueGray-100 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md"
            >
              Enterprise
            </button>
          </div>
        </div>
        <div class="mx-auto px-4 relative w-full md:w-7/12 w-full lg:w-7/12">
          <div class="block">
            <div class="block">
              <div class="flex flex-wrap -mx-4">
                <div class="px-4 relative w-full md:w-6/12">
                  <div
                    class="bg-white mb-6 text-center shadow-lg rounded-lg relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg"
                  >
                    <div
                      class="bg-transparent first:rounded-t px-5 py-3 border-b border-blueGray-200"
                    >
                      <h6 class="font-bold my-2">Standard</h6>
                    </div>
                    <div class="px-4 py-5 flex-auto">
                      <div class="text-5xl mt-0 mb-0 font-bold">$25</div>
                      <span> per month </span>
                      <ul class="mt-6 mb-0 list-none">
                        <li class="py-1 text-blueGray-500">
                          <b class="text-lightBlue-500"> 20GB </b>File Storage
                        </li>
                        <li class="py-1 text-blueGray-500">
                          <b class="text-lightBlue-500"> 15 </b>Users/project
                        </li>
                        <li class="py-1 text-blueGray-500">
                          <b class="text-lightBlue-500"> 4.000 </b>Internal
                          messages
                        </li>
                      </ul>
                    </div>
                    <div
                      class="mt-4 py-6 bg-transparent bg-transparent rounded-b px-4 py-3 border-t border-blueGray-200"
                    >
                      <a href="#pablo" class="text-lightBlue-500"
                        >Request a demo</a
                      >
                    </div>
                  </div>
                </div>
                <div class="px-4 relative w-full md:w-6/12">
                  <div
                    class="bg-white mb-6 text-center shadow-lg rounded-lg relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg"
                  >
                    <div
                      class="bg-transparent first:rounded-t px-5 py-3 border-b border-blueGray-200"
                    >
                      <h6 class="font-bold my-2">Premium</h6>
                    </div>
                    <div class="px-4 py-5 flex-auto">
                      <div class="text-5xl mt-0 mb-0 font-bold">$59</div>
                      <span> per month </span>
                      <ul class="mt-6 mb-0 list-none">
                        <li class="py-1 text-blueGray-500">
                          <b class="text-lightBlue-500"> 50GB </b>File Storage
                        </li>
                        <li class="py-1 text-blueGray-500">
                          <b class="text-lightBlue-500"> 100 </b>Users
                        </li>
                        <li class="py-1 text-blueGray-500">
                          <b class="text-lightBlue-500"> Premium </b>Support
                        </li>
                      </ul>
                    </div>
                    <div
                      class="mt-4 py-6 bg-transparent bg-transparent rounded-b px-4 py-3 border-t border-blueGray-200"
                    >
                      <a href="#pablo" class="text-lightBlue-500"
                        >Request a demo</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden">
              <div class="flex flex-wrap -mx-4">
                <div class="px-4 relative w-full md:w-6/12">
                  <div
                    class="bg-white mb-6 text-center shadow-lg rounded-lg relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg"
                  >
                    <div
                      class="bg-transparent first:rounded-t px-5 py-3 border-b border-blueGray-200"
                    >
                      <h6 class="font-bold my-2">Gold</h6>
                    </div>
                    <div class="px-4 py-5 flex-auto">
                      <div class="text-5xl mt-0 mb-0 font-bold">$100</div>
                      <span> per month </span>
                      <ul class="mt-6 mb-0 list-none">
                        <li class="py-1 text-blueGray-500">
                          <b class="text-lightBlue-500"> 200GB </b>File Storage
                        </li>
                        <li class="py-1 text-blueGray-500">
                          <b class="text-lightBlue-500"> Unlimited </b>Users
                        </li>
                        <li class="py-1 text-blueGray-500">
                          <b class="text-lightBlue-500"> Premium </b>Support
                        </li>
                      </ul>
                    </div>
                    <div
                      class="mt-4 py-6 bg-transparent bg-transparent rounded-b px-4 py-3 border-t border-blueGray-200"
                    >
                      <a href="#pablo" class="text-lightBlue-500"
                        >Request a demo</a
                      >
                    </div>
                  </div>
                </div>
                <div class="px-4 relative w-full md:w-6/12">
                  <div
                    class="bg-white mb-6 text-center shadow-lg rounded-lg relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg"
                  >
                    <div
                      class="bg-transparent first:rounded-t px-5 py-3 border-b border-blueGray-200"
                    >
                      <h6 class="font-bold my-2">Platinum</h6>
                    </div>
                    <div class="px-4 py-5 flex-auto">
                      <div class="text-5xl mt-0 mb-0 font-bold">$135</div>
                      <span> per month </span>
                      <ul class="mt-6 mb-0 list-none">
                        <li class="py-1 text-blueGray-500">
                          <b class="text-lightBlue-500"> 500GB </b>File Storage
                        </li>
                        <li class="py-1 text-blueGray-500">
                          <b class="text-lightBlue-500"> Premium </b>Users
                        </li>
                        <li class="py-1 text-blueGray-500">
                          <b class="text-lightBlue-500"> No time </b>Tracking
                        </li>
                      </ul>
                    </div>
                    <div
                      class="mt-4 py-6 bg-transparent bg-transparent rounded-b px-4 py-3 border-t border-blueGray-200"
                    >
                      <a href="#pablo" class="text-lightBlue-500"
                        >Request a demo</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</main>
<app-footer></app-footer>

<nav
  class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow"
>
  <div
    class="container px-4 mx-auto flex flex-wrap items-center justify-between"
  >
    <div
      class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
    >
      <a
        [routerLink]="['/']"
        class="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
        href="#pablo"
      >
        Over The Stack Solutions
      </a>
      <button
        class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
        type="button"
        (click)="setNavbarOpen()"
      >
        <i class="fas fa-bars"></i>
      </button>
    </div>
    <div
      class="lg:flex flex-grow items-center"
      [ngClass]="{ hidden: !navbarOpen, block: navbarOpen }"
      id="example-navbar-warning"
    >
      <ul class="flex flex-col lg:flex-row list-none mr-auto">
        <li class="flex items-center">
          <a
            class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href=""
          >
            <!-- <i
              class="text-blueGray-400 far fa-file-alt text-lg leading-lg mr-2"
            ></i> -->

            <img
              class="texl-lg leading-lg mr-2"
              src="../../../../assets/img/logo-favicon-32x32.png"
              alt=""
            />
          </a>
        </li>
      </ul>
      <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
        <!-- <li class="flex items-center">
          <app-index-dropdown class="block"></app-index-dropdown>
        </li> -->
        <li class="flex items-center">
          <a
            class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.facebook.com/Overthestacksolutions"
            target="_blank"
          >
            <i
              class="text-blueGray-700 hover:text-blueGray-400 fab fa-facebook text-lg leading-lg"
            ></i>
            <span class="lg:hidden inline-block ml-2">facebook</span>
          </a>
        </li>

        <li class="flex items-center">
          <a
            class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.linkedin.com/company/over-the-stack-solutions/"
            target="_blank"
          >
            <i
              class="text-lightBlue-otss hover:text-blueGray-400 fab fa-linkedin-in text-lg leading-lg"
            ></i>
            <span class="lg:hidden inline-block ml-2">linkedIn</span>
          </a>
        </li>

        <li class="flex items-center">
          <a
            class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.instagram.com/overthestacksolutions/"
            target="_blank"
          >
            <i
              class="text-orange-otss hover:text-blueGray-400 fa-brands fa-instagram text-lg leading-lg"
            ></i>
            <span class="lg:hidden inline-block ml-2">Instagram</span>
          </a>
        </li>

        <li class="flex items-center">
          <a href="mailto:connect@overthestack.com" target="_blank">
            <button
              class="bg-red-600 text-white active:bg-red-700 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              type="button"
            >
              <!-- <i class="fas fa-arrow-alt-circle-down"></i> -->
              Contact Us
            </button>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<a
  class="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
  href="#pablo"
  (click)="toggleDropdown($event)"
  #btnDropdownRef
>
  Demo Pages
</a>
<div
  class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
  [ngClass]="dropdownPopoverShow ? 'block' : 'hidden'"
  #popoverDropdownRef
>
  <span
    class="text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
  >
    Admin Layout
  </span>
  <a
    [routerLink]="['/admin/dashboard']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Dashboard
  </a>
  <a
    [routerLink]="['/admin/settings']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Settings
  </a>
  <a
    [routerLink]="['/admin/tables']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Tables
  </a>
  <a
    [routerLink]="['/admin/maps']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Maps
  </a>
  <div class="h-0 mx-4 my-2 border border-solid border-blueGray-100"></div>
  <span
    class="text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
  >
    Auth Layout
  </span>
  <a
    [routerLink]="['/auth/login']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Login
  </a>
  <a
    [routerLink]="['/auth/register']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Register
  </a>
  <div class="h-0 mx-4 my-2 border border-solid border-blueGray-100"></div>
  <span
    class="text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
  >
    No Layout
  </span>
  <a
    [routerLink]="['/landing']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Landing
  </a>
  <a
    [routerLink]="['/profile']"
    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
  >
    Profile
  </a>
</div>

import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-landing-new',
  templateUrl: './landing-new.component.html',
  styleUrl: './landing-new.component.css',
})
export class NewLandingComponent implements OnInit {
  @ViewChild('owlCar') carousel;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    margin: 0,
    autoplay: true,
    autoplayHoverPause: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ["",""],
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    items: 1,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false,
  };

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.renderer.addClass(this.carousel.el.nativeElement.childNodes[0], 'home-slider');
  }
}
